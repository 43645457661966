import React from "react";

import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";

export default function Post({ data: { postData } }) {
  return (
    <Layout>
      <article className="container mx-auto max-w-screen-lg">
        <h1 className="text-2xl font-bold mb-2">{postData.title}</h1>
        <GatsbyImage
          alt={postData.image.alt}
          image={postData.image.gatsbyImageData}
        />
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: postData.content }}
        />
      </article>
    </Layout>
  );
}

export const query = graphql`
  query queryPost($id: String!) {
    postData: datoCmsPost(originalId: { eq: $id }) {
      title
      slug
      originalId
      position
      content
      image {
        alt
        gatsbyImageData
      }
      seoMetatags {
        title
        description
      }
    }
  }
`;

export function Head({ data }) {
  const { title, description } = data?.postData?.seoMetatags || {};
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
    </>
  );
}
